import '../styles/index.scss';

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

//Load Screen fix
const hideView = document.getElementById("hideView");
setTimeout(() => {
	hideView.classList.add( "opacity-100", "transition", "ease-in", "duration-500" );
	hideView.classList.remove("opacity-0");
}, 1000);

//SCROLL SMOOTH
//https://github.com/locomotivemtl/locomotive-scroll
import LocomotiveScroll from 'locomotive-scroll';
const scroll = new LocomotiveScroll({
	el: document.querySelector('[data-scroll-container]'),
	direction: 'vertical',
	smooth: true,	//Active Smooth mode
	getDirection: true, //Add direction to scroll event
	multiplier: 0.7,
	lerp: 0.1,
});

//SWIPER CAROUSEL
//https://swiperjs.com/get-started
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const swiperDataPurePlayer = new Swiper('.swiper-datapureplayer', {
	// configure Swiper to use modules
	modules: [Navigation, Pagination],
	slidesPerView: "auto",
	spaceBetween: 30,
	breakpoints: {
		320: {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 16,
			pagination: false,
			navigation:false,
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 16,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: '#js-next1',
				prevEl: '#js-prev1',
			},
		}
	}
});
const swiperTechnoAddict = new Swiper('.swiper-techno-addict', {
	// configure Swiper to use modules
	modules: [Navigation, Pagination],
	slidesPerView: "auto",
	spaceBetween: 30,
	breakpoints: {
		320: {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 16,
			pagination: false,
			navigation:false,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 8,
		}
	}
});
const swiperDataLovers = new Swiper('.swiper-data-lovers', {
	// configure Swiper to use modules
	modules: [Navigation, Pagination],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			centeredSlides: false,
			spaceBetween: 0,
			pagination: false,
			navigation:false,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 0,
			pagination: false,
			navigation: {
				nextEl: '#js-next2',
				prevEl: '#js-prev2',
			},
		}
	}
});
const swiperPartenaireTech1 = new Swiper('.swiper-partenaires-tech-1', {
	modules: [Navigation],
	spaceBetween: 30,
	breakpoints: {
		320: {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 16,
			navigation:false,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 20,
			navigation: {
				nextEl: '#js-next-partenaires-1',
				prevEl: '#js-prev-partenaires-1',
			},
		}
	}
});
const swiperPartenaireTech2 = new Swiper('.swiper-partenaires-tech-2', {
	modules: [Navigation],
	spaceBetween: 30,
	breakpoints: {
		320: {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 16,
			navigation:false,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 20,
			navigation: {
				nextEl: '#js-next-partenaires-2',
				prevEl: '#js-prev-partenaires-2',
			},
		}
	}
});
const swiperDataStoriesCLoud = new Swiper('.swiper-data-stories-cloud', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
	 320: {
		 slidesPerView: 'auto',
		 spaceBetween: 10,
	 },
	 768: {
			 slidesPerView: 'auto',
			 spaceBetween: 10,
			 navigation: {
				 nextEl: '#js-next-cloud',
				 prevEl: '#js-prev-cloud',
			 },
		 }
	}
});
const swiperDataStoriesManagement = new Swiper('.swiper-data-stories-management', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			spaceBetween: 10,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 10,
			navigation: {
				nextEl: '#js-next-management',
				prevEl: '#js-prev-management',
			},
		}
	}
});
const swiperDataStoriesScience = new Swiper('.swiper-data-stories-science', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			spaceBetween: 10,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 10,
			navigation: {
				nextEl: '#js-next-science',
				prevEl: '#js-prev-science',
			},
		}
	}
});
const swiperDataStoriesVisualisation = new Swiper('.swiper-data-stories-visualisation', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			spaceBetween: 10,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 10,
			navigation: {
				nextEl: '#js-next-visualisation',
				prevEl: '#js-prev-visualisation',
			},
		}
	}
});
const swiperDataStoriesStorage = new Swiper('.swiper-data-stories-storage', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			spaceBetween: 10,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 10,
			navigation: {
				nextEl: '#js-next-storage',
				prevEl: '#js-prev-storage',
			},
		}
	}
});
const swiperCarriere = new Swiper('.strate__carriere', {
	modules: [Navigation, Pagination],
	slidesPerView: 1,
	spaceBetween: 0,
	breakpoints: {
		320: {
			slidesPerView: 3,
			spaceBetween: 30,
			centeredSlides: false,
			pagination: false,
			navigation:false,
		},
		768: {
			slidesPerView: 1,
			spaceBetween: 0,
			// direction: "vertical",
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: '#js-next3',
				prevEl: '#js-prev3',
			},
		}
	}
});

// TEXT SCROLL
let view = document.getElementsByClassName('manifesto');
// let style = window.getComputedStyle(view[0], null); // Disable for Fix Console alert
// let heightValue = style.height.split('.',1)[0];     // Disable for Fix Console alert
let nodes = document.querySelectorAll('.maskGroup .line');
if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
	scroll.on('call', (value, way, obj) => {
		scroll.on('scroll', ({ limit, scroll }) => {
			let progress;
			if (value === 'CALL_VALUE_PHILOSOPHIE') {
				progress = scroll.y / limit.y * 20000;
			} else if (value === 'CALL_PARTENAIRE_A') {
				progress = scroll.y / limit.y * 8000;
			} else if (value === 'CALL_PARTENAIRE_B') {
				progress = scroll.y / limit.y * 3200;
			} else if (value === 'CALL_CARRIERE') {
				progress = scroll.y / limit.y * 15000;
			} else if (value === 'CALL_VALUE_INSTITUTE') {
				progress = scroll.y / limit.y * 5000
			}
			// Delay start width per line
			for(let i=0; i<nodes.length; i++) {
				const content = nodes[i].querySelector('.content')
				const mask = content.querySelector('.mask')
				const px = progress - (i*100) > 0 ? progress - (i*100) : 0 ;
				mask.style.width = `${px}px`
			}
		});
	});
}else{
	scroll.on('call', (value, way, obj) => {
		scroll.on('scroll', ({ limit, scroll }) => {
			let progress;
			if (value === 'CALL_VALUE_PHILOSOPHIE') {
				progress = scroll.y / limit.y * 15000;
			} else if (value === 'CALL_PARTENAIRE_A') {
				progress = scroll.y / limit.y * 8000;
			} else if (value === 'CALL_PARTENAIRE_B') {
				progress = scroll.y / limit.y * 3500;
			} else if (value === 'CALL_CARRIERE') {
				progress = scroll.y / limit.y * 6000;
			} else if (value === 'CALL_VALUE_INSTITUTE') {
				progress = scroll.y / limit.y * 5000
			}
			// Delay start width per line
			for(let i=0; i<nodes.length; i++) {
				const content = nodes[i].querySelector('.content')
				const mask = content.querySelector('.mask')
				const px = progress - (i*100) > 0 ? progress - (i*100) : 0 ;
				mask.style.width = `${px}px`
			}
		});
	});
}


// Scroll update all pages (fix bottom page - cut off)
// Refresh
document.addEventListener('DOMContentLoaded', function() {
	function ScrollUpdateDelay() {
		setTimeout(function(){ scroll.update(); }, 500);
		setTimeout(function(){ scroll.update(); }, 1000);
		setTimeout(function(){ scroll.update(); }, 1500);
		setTimeout(function(){ scroll.update(); }, 2000);
		setTimeout(function(){ scroll.update(); }, 2500);
		setTimeout(function(){ scroll.update(); }, 3000);
	}
	ScrollUpdateDelay();
});

/* Locomotive Anchor Scroll */
const anchorLinks = document.querySelectorAll(
	 'a[href^=\\#]:not([href$=\\#])'
);
anchorLinks.forEach((anchorLink) => {
	let hashval = anchorLink.getAttribute('href');
	let target = document.querySelector(hashval);
	anchorLink.addEventListener('click', (e) => {
		e.preventDefault();
		e.stopPropagation();

		anchorLinks.forEach((anchorLink) => {
			anchorLink.classList.remove('active');
		});

		e.target.classList.add('active');

		scroll.scrollTo(target);
	});
});

const swiperTeamDirection = new Swiper('.swiper-team-direction', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			centeredSlides: false,
			spaceBetween: 16,
			navigation:false,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 90,
			navigation: {
				nextEl: '#js-next-direction',
				prevEl: '#js-prev-direction',
			},
		}
	}
});
const swiperTeamManagement = new Swiper('.swiper-team-management', {
	// configure Swiper to use modules
	modules: [Navigation],
	breakpoints: {
		320: {
			slidesPerView: 'auto',
			centeredSlides: false,
			spaceBetween: 16,
			navigation:false,
		},
		768: {
			slidesPerView: 'auto',
			spaceBetween: 90,
			navigation: {
				nextEl: '#js-next-manage',
				prevEl: '#js-prev-manage',
			},
		}
	}
});

console.log('Front : Mickael Cambefort - Ipsosenso');
